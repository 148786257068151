<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card :title="$route.name" icon="mdi-apple-finder">
          <v-card-text class="py-0">
            <v-row>
              <v-col cols="4">
                <v-text-field label="Search" v-model="search" />
              </v-col>

              <v-spacer />

              <v-radio-group
                v-model="radioOption"
                row
                class="d-flex align-center justify-center"
              >
                <v-radio
                  v-for="item in radioItem"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </v-radio-group>
            </v-row>
          </v-card-text>

          <v-card-text class="py-0 mb-8">
            <v-row>
              <v-col cols="3" v-for="item in infoitems" :key="item.title">
                <v-sheet
                  elevation="4"
                  height="80px"
                  width="100%"
                  class="d-flex align-center justify-center"
                >
                  <label class="display-1">{{ item.title }}</label>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <TableCRUD :headers="headers" :search="search" />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CollectionRecord",
  components: {
    TableCRUD: () => import("@/components/tableCRUD")
  },
  data() {
    return {
      search: "",
      radioOption: "Received payment",
      radioItem: [
        this.$vuetify.lang.t("$vuetify.payed"),
        this.$vuetify.lang.t("$vuetify.PendingOpenInvoice"),
        this.$vuetify.lang.t("$vuetify.payable")
      ],
      infoitems: [
        {
          title: this.$vuetify.lang.t("$vuetify.SalesTTL"),
          value: "",
          color: "#00b0ff"
        },
        {
          title: this.$vuetify.lang.t("$vuetify.payed"),
          value: "",
          color: "#ff9100"
        },
        {
          title: this.$vuetify.lang.t("$vuetify.PendingOpenInvoice"),
          value: "",
          color: "#e91e63"
        },
        {
          title: this.$vuetify.lang.t("$vuetify.payable"),
          value: "",
          color: "#d500f9"
        }
      ],
      headers: [
        { text: this.$vuetify.lang.t("$vuetify.itemNo") },
        {
          text: this.$vuetify.lang.t("$vuetify.companyName")
        },
        {
          text: this.$vuetify.lang.t("$vuetify.TotalAmount")
        },
        { text: this.$vuetify.lang.t("$vuetify.currency") },
        {
          text: this.$vuetify.lang.t("$vuetify.paymentterm")
        },
        {
          text: this.$vuetify.lang.t("$vuetify.orderDate")
        },
        {
          text: this.$vuetify.lang.t("$vuetify.remarks")
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80"
        }
      ]
    };
  },
};
</script>

<style></style>
